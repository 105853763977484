import React, {ReactNode} from 'react';
import {Wrapper} from './TopBar.styles';
import logo from '../../assets/logo.svg';
import ContextMenu from '../ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import {changeLanguage} from '../../logic/language/language';
import {Languages} from '../../logic/core/models';
import iconDropdown from '../../assets/icon-dropdown.svg';
import backIcon from '../../assets/icon-left.svg';
import {AppRoutes} from '../../logic/core/routes';
import {useBaseScreenHook} from '../../logic/core/base-screen.hook';
import {useLogout} from '../../logic/auth/auth';
import flagPL from '../../assets/flag-pl.svg';
import flagEN from '../../assets/flag-en.svg';
import flagIT from '../../assets/flag-it.svg'
import flagDE from '../../assets/flag-de.svg'
import flagAR from '../../assets/flag-ar.svg'
import {useAppStateHook} from '../../logic/core/appState.hook';
import {NavLink, useParams} from 'react-router-dom';

const TopBar = ({backArrow, url, logoutAction, children}: { backArrow?: boolean, url?: string, logoutAction?: () => void , children?: ReactNode | ReactNode[]}) => {
    const {t, navigate, i18n} = useBaseScreenHook();
    const {logout} = useLogout();
    const {appState} = useAppStateHook();
    const {organizationId} = useParams();
    const _getFlagIcon = (lang?: Languages) => {
        switch (lang || i18n.language) {
            case Languages.PL:
                return flagPL;
            case Languages.EN:
                return flagEN;
            case Languages.IT:
                return flagIT;
            case Languages.DE:
                return flagDE;
            case Languages.AR:
                return flagAR;
            default:
                return '';
        }
    }
    const _getBackUrl = (): string => {
        if (url) {
            return url;

        }
        if (backArrow) {
            if (organizationId) {
                const eventSourceLocation: string | null = localStorage.getItem('event-source-location');
                if (eventSourceLocation) {
                    return eventSourceLocation;
                }
                return `/`
            } else {
                return '/'
            }
        } else {
            return '/'
        }
    }

    return <Wrapper>
        <div className="top-bar">
            {appState.user
                ? <NavLink to={_getBackUrl()} className="logo">
                    {
                        backArrow
                            ? <img src={backIcon} alt="icon" className="icon-back"/>
                            : null
                    }
                    <img src={logo} alt="logo"/>
                </NavLink>
                : <img src={logo} alt="logo"/>
            }

            {children || null}

            <div className="left-bar">


                <ContextMenu element={
                    <div className="lang">
                        <div className="icon">
                            <img src={_getFlagIcon()} alt="flag"/>
                        </div>
                        <div className="name">
                            {i18n.language}
                        </div>
                    </div>
                }>

                    <ContextMenuItem onClick={() => changeLanguage(Languages.PL)}>
                        <div className="lang">
                            <div className="icon">
                                <img src={_getFlagIcon(Languages.PL)} alt="flag"/>
                            </div>
                            <div className="name">
                                {t('lang.PL')}
                            </div>
                        </div>
                    </ContextMenuItem>

                    <ContextMenuItem onClick={() => changeLanguage(Languages.EN)}>
                        <div className="lang">
                            <div className="icon">
                                <img src={_getFlagIcon(Languages.EN)} alt="flag"/>
                            </div>
                            <div className="name">
                                {t('lang.EN')}
                            </div>
                        </div>
                    </ContextMenuItem>

                    <ContextMenuItem onClick={() => changeLanguage(Languages.IT)}>
                        <div className="lang">
                            <div className="icon">
                                <img src={_getFlagIcon(Languages.IT)} alt="flag"/>
                            </div>
                            <div className="name">
                                {t('lang.IT')}
                            </div>
                        </div>
                    </ContextMenuItem>

                    <ContextMenuItem onClick={() => changeLanguage(Languages.DE)}>
                        <div className="lang">
                            <div className="icon">
                                <img src={_getFlagIcon(Languages.DE)} alt="flag"/>
                            </div>
                            <div className="name">
                                {t('lang.DE')}
                            </div>
                        </div>
                    </ContextMenuItem>

                    <ContextMenuItem onClick={() => changeLanguage(Languages.AR)}>
                        <div className="lang">
                            <div className="icon">
                                <img src={_getFlagIcon(Languages.AR)} alt="flag"/>
                            </div>
                            <div className="name">
                                {t('lang.AR')}
                            </div>
                        </div>
                    </ContextMenuItem>

                </ContextMenu>
                {
                    appState.user ?
                        <>
                            <div className="divider"></div>

                            <ContextMenu element={<div className="user-box">
                                <div className="avatar">
                                    {appState.user?.firstName.substr(0, 1)}
                                    {appState.user?.lastName.substr(0, 1)}
                                </div>

                                <img src={iconDropdown} alt="icon"/>

                            </div>}>

                                <ContextMenuItem onClick={() => navigate(`/${AppRoutes.account}/${AppRoutes.profile}`)}>
                                    {t('home.profile')}
                                </ContextMenuItem>
                                <ContextMenuItem onClick={() => logoutAction ? logoutAction() : logout()}>
                                    {t('home.logout')}
                                </ContextMenuItem>

                            </ContextMenu>
                        </>

                        : null
                }


            </div>
        </div>

    </Wrapper>
}

export default TopBar;
